import * as analytics from "./analytics.utils";
import * as date from "./date.utils";
import * as debug from "./debug.utils";
import * as error from "./error.utils";
import * as form from "./form.utils";
import * as languages from "./language.utils";
import * as session from "./session.utils";
import * as style from "./style.utils";
import * as toast from "./toast.utils";

export * from "./analytics.utils";
export * from "./date.utils";
export * from "./debug.utils";
export * from "./error.utils";
export * from "./form.utils";
export * from "./language.utils";
export * from "./style.utils";
export * from "./toast.utils";
export * from "./session.utils";

const utils = {
  analytics,
  debug,
  toast,
  form,
  languages,
  date,
  error,
  style,
  session,
};

export default utils;
