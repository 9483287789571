import { isWindowAvailable } from "@remix-pwa/client";

export const getScaleFactor = () => {
  if (!isWindowAvailable()) return "1";

  const root = document.documentElement;
  return getComputedStyle(root).getPropertyValue("--text-scale-factor");
};

export const getScaleLabelKey = () => {
  const scale = getScaleFactor();
  switch (scale) {
    case "0.6":
      return "label_other_font_very_small";
    case "0.85":
      return "label_other_font_small";
    case "1.15":
      return "label_other_font_large";
    case "1.3":
      return "label_other_font_very_large";
    case "1":
    default:
      return "label_other_default";
  }
};
