import * as Sentry from "@sentry/remix";

export type ErrorCodes = "LECTURE_MESSAGE_DUPLICATE" | "LECUTURE_MESSAGE_AUDIO_SKIPPED" | "ACCEPT_TERMS";

// NOTE can be used for test purposes, works only on client side
export const capture = (errorCode: ErrorCodes, message?: unknown) => {
  const error = new Error(errorCode);
  error.name = error.message; // NOTE Display nice error titles in Sentry
  Sentry.captureException(error, { extra: { message } });
};
