import { withZod } from "@rvf/zod";
import { z } from "zod";

import { supportedLanguages } from "./language.utils";

export const validatorUserProfile = withZod(
  z.object({
    name: z
      .string()
      .min(1, { message: "prompt_error_required_field" })
      .max(32, { message: "prompt_error_user_name_too_long" })
      .optional(),
    languageName: z
      .string()
      .refine((v) => Object.keys(supportedLanguages).includes(v), { message: "prompt_error_required_field" })
      .optional(),
    isAutoPlay: z
      .string()
      .transform((value) => value.toLowerCase() === "true")
      .optional(),
  }),
);
