import {
  getAnalytics,
  logEvent as logEventToAnalytics,
  setAnalyticsCollectionEnabled,
  setConsent,
  setUserId,
} from "firebase/analytics";
import i18next from "i18next";

import { auth } from "~/firebase.client";

export enum Events {
  RegulationsAccept = "audience_regulations_acceptContinue", // { origin: "scanner" | "landingpage" | "message" | "browser" }

  ProfileView = "audience_profile_view", // { cameFrom: "audience_regulations" | "audience_lecture" }
  ProfileClose = "audience_profile_close",
  ProfileSubmit = "audience_profile_submit", // { result: "success" | "invalid" | "failed", name, language }

  JoinLectureView = "audience_joinlecture_view", // { isBackFromLecture: true | false }
  JoinLectureClose = "audience_joinlecture_close",
  JoinLectureKeyboard = "audience_joinlecture_keyboardCode",
  JoinLectureSubmitCode = "audience_joinlecture_submitCode",
  JoinLectureError = "audience_joinlecture_error", // { error: "attemptsLimit" | "incorrectCode" }

  ScanQrCodeView = "audience_scanqrcode_view",
  ScanQrCodeClose = "audience_scanqrcode_close",
  ScanQrCodeFail = "audience_scanqrcode_resultFailure", // { error: "blockedCamera" | "unknown" }

  LectureView = "audience_lecture_view", // { origin: "scanner" | "landingpage" | "message" | "browser" | "link" }
  LectureClose = "audience_lecture_close",
  LectureWaiting = "audience_lecture_waiting",
  LectureTranslationTtsReinit = "audience_lecture_translationTtsReinit",

  LectureQuestionInput = "audience_lecture_question_input",
  LectureQuestionSubmit = "audience_lecture_question_submit",
  LectureQuestionResul = "audience_lecture_question_result", // { result: "success" | "lectureNotStarted" | "attemptsTime" | "errorUnknown" }

  LectureKebabMenu = "audience_lecture_kebabMenu", // { type: "leave" | "profile" | "settings" }
  LectureLeaveDialog = "audience_lecture_leave_dialog_view",
  LectureLeaveConfirm = "audience_lecture_leave_confirm",
  LectureLeaveCancel = "audience_lecture_leave_cancel",

  SettingsView = "audience_settings_view",
  SettingsClose = "audience_settings_close",
  SettingsAutoPlay = "audience_settings_autoplayToggle", // { newState: true | false }
  SettingsRegulationsView = "audience_settings_regulations_view",
  SettingsRegulationsClose = "audience_settings_regulations_close",
  SettingsFontSizeView = "audience_settings_fontSize_view", // { fontSize: 0.6 | 0.85 | 1 | 1.15 | 1.3 }
  SettingsFontSizeChange = "audience_settings_fontSize_change", // { fontSize: 0.6 | 0.85 | 1 | 1.15 | 1.3 }
  SettingsFontSizeCancel = "audience_settings_fontSize_cancel",
  SettingsQuickerAccessView = "audience_quickerAccess_view",
  SettingsQuickerAccessAccept = "audience_quickerAccess_accept", // { platform: "android" | "ios" }
  SettingsQuickerAccessClose = "audience_quickerAccess_close",
}

type ParamsType = { [key: string]: unknown };
type EventQueueItem = ParamsType & { name: Events };

let shouldCollectMissedEvents = true;
let missedEventsQueue: EventQueueItem[] = [];

export const logEvent = (event: Events, params?: ParamsType) => {
  const analytics = getAnalytics();

  const eventParams = {
    ...params,
    application: "Audience",
    language: i18next.language,
    screen: getScreenNameFromEvent(event),
    fId: auth.currentUser?.uid,
  };

  if (shouldCollectMissedEvents) {
    missedEventsQueue.push({ name: event, ...eventParams });
  } else {
    logEventToAnalytics(analytics, event, eventParams);
  }
};

export const getScreenNameFromEvent = (event: Events): string => {
  const firstUnderscoreIndex = event.indexOf("_");
  if (firstUnderscoreIndex !== -1) {
    return event.slice(0, event.indexOf("_", firstUnderscoreIndex + 1));
  }

  return event;
};

export const enableAnalytics = (userUid: string | null) => {
  const analytics = getAnalytics();

  if (userUid) {
    setAnalyticsCollectionEnabled(analytics, true);
    setConsent({
      analytics_storage: "granted", // Enables storage, such as cookies, related to analytics (for example, visit duration)
      functionality_storage: "granted", // Enables storage that supports the functionality of the website or app such as language settings
      security_storage: "granted", // Enables storage related to security such as authentication functionality, fraud prevention and other user protection
      ad_storage: "denied", // Enables storage, such as cookies, related to advertising
      ad_user_data: "denied", // Sets consent for sending user data to Google for advertising purposes
      ad_personalization: "denied", // Sets consent for personalized advertising
      personalization_storage: "denied", // Enables storage related to personalization such as video recommendations
    });
    setUserId(analytics, userUid);

    // NOTE some events might be called before analytics initializes ( we need to wait for userId), as a workaround we save them here and log after the initialization is success
    if (shouldCollectMissedEvents) {
      shouldCollectMissedEvents = false;
      missedEventsQueue.forEach((e) => {
        const { name, ...rest } = e;
        logEvent(name, rest);
      });
      missedEventsQueue = [];
    }
  }
};
