export const removeSessionMessagesByChannelId = (channelId: string) => {
  const sessionMessages = sessionStorage.getItem("messages");
  if (!sessionMessages) return;

  const messages = JSON.parse(sessionMessages);
  if (channelId in messages) {
    delete messages[channelId];
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }
};
